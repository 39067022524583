import auth from '@/middleware/auth'
import { RESOURCE_PROVIDERS } from '@/shared/constants/resources'

export default [
  {
    path: '/providers',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROVIDERS,
      headerVisible: false,
    },
    name: 'providers',
    component: () => import('@/views/providers/List.vue'),
  },
  {
    path: '/providers/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROVIDERS,
      navActiveLink: 'providers',
      title: 'Editar proveedor',
    },
    name: 'editProvider',
    component: () => import('@/views/providers/Edit.vue'),
  },
  {
    path: '/providers/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROVIDERS,
      navActiveLink: 'providers',
      title: 'Proveedor',
    },
    name: 'viewProvider',
    component: () => import('@/views/providers/View.vue'),
  },
  {
    path: '/providers/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PROVIDERS,
      navActiveLink: 'providers',
      title: 'Crear proveedor',
    },
    name: 'createProvider',
    component: () => import('@/views/providers/Create.vue'),
  },
]
