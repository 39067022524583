/* eslint-disable no-param-reassign */
import axios from 'axios'
import jsonToFormData from '@ajoelp/json-to-formdata'

function formatBodyRequest(data) {
  if (!data) {
    return null
  }

  const body = {}
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Object && data[key].id) {
      body[`${key}_id`] = data[key].id
    } else {
      body[key] = data[key]
    }
  })

  return jsonToFormData(body, { arrayIndexes: true, excludeNull: true })
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  transformRequest: [
    data => formatBodyRequest(data),
  ],
})

export default {
  install(Vue) {
    axiosInstance.interceptors.request.use(request => {
      const token = JSON.parse(localStorage.getItem('token'))

      if (token && token.token) {
        request.headers.Authorization = `${token.token_type} ${token.token}`
      }
      return request
    }, err => Promise.reject(err))

    Vue.prototype.$http = axiosInstance
  },
}
