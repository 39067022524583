import auth from '@/middleware/auth'
import { RESOURCE_PRODUCTS } from '@/shared/constants/resources'

export default [
  {
    path: '/products',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PRODUCTS,
      headerVisible: false,
    },
    name: 'products',
    component: () => import('@/views/products/List.vue'),
  },
  {
    path: '/products/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PRODUCTS,
      navActiveLink: 'products',
      title: 'Producto',
    },
    name: 'viewProduct',
    component: () => import('@/views/products/View.vue'),
  },
  {
    path: '/products/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PRODUCTS,
      navActiveLink: 'products',
      title: 'Crear producto',
    },
    name: 'createProduct',
    component: () => import('@/views/products/Create.vue'),
  },
  {
    path: '/products/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PRODUCTS,
      navActiveLink: 'products',
      title: 'Editar producto',
    },
    name: 'editProduct',
    component: () => import('@/views/products/Edit.vue'),
  },
]
