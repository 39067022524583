import { BaseApi } from '@/api/base-api'
import { RESOURCE_JOBS } from '@/shared/constants/resources'
import Vue from 'vue'
import DownloadService from '@/shared/services/download-service'

export const JOB_INFO_TYPE_ECONOMIC_STATE = 'economic-state'
export const JOB_INFO_TYPE_ORDERS = 'orders'
export const JOB_INFO_TYPE_PURCHASE_INVOICES = 'purchase-invoices'
class JobsApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }
  deleteContract(jobId, contractId, notificationsVisible = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.delete(`/${this.resource}/delete_contract/${jobId}/${contractId}`)
        if (notificationsVisible) {
          Vue.$toast('Contrato eliminado con éxito.')
        }

        resolve(response.data.data)
      } catch (error) {
        console.error(error)
        if (notificationsVisible) {
          Vue.$toast.error('Ocurrió un error al eliminar el contrato del job. Por favor inténtelo de nuevo mas tarde.')
        }

        reject(error)
      }
    })
  }
  getJobInfo(id, type, job_contract_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${this.resource}/show-tabs/${id}`, { type, job_contract_id })
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al obtener la información del job. Por favor, inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
  async printJob(jobId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/print-job/${jobId}`, {}, { responseType: 'blob' })
        const filename = DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(response.data, filename)
        resolve()
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Error al descargar el archivo. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
  async downloadAssists (job_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`assists/export/${ job_id }`, {}, { responseType: 'blob' })
        const filename = DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(response.data, filename)
        resolve()
      } catch (error) {
        Vue.$toast.error('Error al descargar el archivo. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
}

export default new JobsApi(RESOURCE_JOBS)
