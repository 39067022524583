export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/View.vue'),
  },
  {
    path: '/profile/edit',
    name: 'editProfile',
    component: () => import('@/views/profile/Edit.vue'),
  },
]
