import { RESOURCE_ACTION_ALL } from '@/shared/constants/resourceActions'

export default async function auth({ next, store, to }) {
  const isUserLoggedIn = await store.dispatch('auth/isUserLoggedIn')
  if (!isUserLoggedIn) {
    return next({ name: 'login' })
  }

  const isTokenExpired = await store.dispatch('auth/isTokenExpired')
  if (isTokenExpired) {
    store.commit('notifications', { title: 'Sesión expirada, vuelva a iniciar sesión.', variant: 'danger' }, { root: true })
    store.commit('setShowNotifications', true, { root: true })
    store.dispatch('auth/clearAuthData')
    return next({ name: 'login' })
  }

  if (!store.getters['auth/canAccessResource'](to.meta.resource, to.meta.resourceAction || RESOURCE_ACTION_ALL)) {
    store.dispatch('auth/clearAuthData')
    return next({ name: 'error-401' })
  }

  return next()
}
