import auth from '@/middleware/auth'
import { RESOURCE_MAINTENANCE_CERTIFICATIONS } from '@/shared/constants/resources'

export default
[
  {
    path: '/maintenance-certifications',
    meta: {
      middleware: [auth],
      resource: RESOURCE_MAINTENANCE_CERTIFICATIONS,
      headerVisible: false,
    },
    name: 'maintenanceCertifications',
    component: () => import('@/views/maintenance-certifications/List.vue'),
  },
  {
    path: '/maintenance-certifications/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_MAINTENANCE_CERTIFICATIONS,
      navActiveLink: 'maintenanceCertifications',
      title: 'Certificado mantenimiento',
    },
    name: 'viewMaintenanceCertification',
    component: () => import('@/views/maintenance-certifications/View.vue'),
  },
]