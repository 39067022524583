import MaintenanceCertificationsApi from "@/api/maintenance-certifications";
import ApiRestService from '@/api/base-api'
import { RESOURCE_MAINTENANCE_CERTIFICATIONS_LINES } from '@/shared/constants/resources'

const state = {
  maintenanceCertification: null,
  maintenanceCertificationLines: null,
  filters: {},
  search: null,
};

const mutations = {
  setFilters(_state, filters) {
    state.filters = filters;
  },
  setSearch(_state, search) {
    state.search = search;
  },
  setMaintenanceCertification(_state, data) {
    state.maintenanceCertification = data
  },
  setMaintenanceCertificationLines(_state, data) {
    state.maintenanceCertificationLines = data
  },
  setTax(_state, tax) {
    state.maintenanceCertification.tax = tax
  }
};


const actions = {
  loadMaintenanceCertification({ commit }, id) {
    if(!id) {
      return
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await Promise.all([
          MaintenanceCertificationsApi.get(id),
          ApiRestService.list({ filters: { maintenance_certification_id: id } }, RESOURCE_MAINTENANCE_CERTIFICATIONS_LINES),
        ]) 
        
        commit('setMaintenanceCertification', response[0].data)
        commit('setMaintenanceCertificationLines', response[1].data)
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  },
  setTax({ commit }, tax) {
    if(!state.maintenanceCertification) {
      return
    }

    commit('setTax', tax)
  }
}

const getters = {
  getMaintenanceCertification: () => state.maintenanceCertification,
  getMaintenanceCertificationLines: () => state.maintenanceCertificationLines,
  getFilters: () => state.filters,
  getSearch: () => state.search,
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
