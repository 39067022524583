import auth from '@/middleware/auth'
import { RESOURCE_JOBS, RESOURCE_JOBS_REPORTS } from '@/shared/constants/resources'

export default [
  {
    path: '/jobs',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS,
      headerVisible: false,
    },
    name: 'jobs',
    component: () => import('@/views/jobs/List.vue'),
  },
  {
    path: '/jobs/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS,
      navActiveLink: 'jobs',
      title: 'Job',
    },
    name: 'viewJob',
    component: () => import('@/views/jobs/View.vue'),
  },
  {
    path: '/jobs/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS,
      navActiveLink: 'jobs',
      title: 'Crear job',
    },
    name: 'createJob',
    component: () => import('@/views/jobs/Create.vue'),
  },
  {
    path: '/jobs/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS,
      navActiveLink: 'jobs',
      title: 'Editar job',
    },
    name: 'editJob',
    component: () => import('@/views/jobs/Edit.vue'),
  },
  {
    path: '/jobs/renew-offer/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS,
      navActiveLink: 'jobs',
      title: 'Renovar oferta job',
    },
    name: 'renewOfferJob',
    component: () => import('@/views/jobs/RenewOffer.vue'),
  },
  {
    path: '/job/reports',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS_REPORTS,
      title: 'Informe cartera contracting',
    },
    name: 'jobReports',
    component: () => import('@/views/jobs-reports/List.vue'),
  },
  {
    path: '/job/reports/services',
    meta: {
      middleware: [auth],
      resource: RESOURCE_JOBS_REPORTS,
      title: 'Informe cartera service',
    },
    name: 'jobReportsServices',
    component: () => import('@/views/jobs-reports-services/List.vue'),
  },
]
