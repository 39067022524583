import Vue from 'vue'
import axios from 'axios'

const RESOURCE_NAME = '/auth'

export default {
  login(email, password) {
    return axios.post(`${process.env.VUE_APP_ROOT_API}${RESOURCE_NAME}/login`, {
      email,
      password,
    })
  },
  refreshToken() {
    return Vue.prototype.$http.post(`${RESOURCE_NAME}/refresh`)
  },
  recoverPassword(email) {
    return Vue.prototype.$http.post(`${RESOURCE_NAME}/password/email`, {
      email,
    })
  },
  resetPassword(email, password, password_confirmation, token) {
    return Vue.prototype.$http.post(`${RESOURCE_NAME}/password/reset`, {
      email,
      password,
      password_confirmation,
      token,
    })
  },
}
