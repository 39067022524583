export default {
  resolveAndDownloadBlob(dataBlob, filename, extension = null) {
    if (!dataBlob || dataBlob.size === 0) {
      return
    }

    let fullname = extension ? `${filename}.${extension}` : filename
    fullname = decodeURI(fullname)
    const url = window.URL.createObjectURL(dataBlob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fullname)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
  },
  getFilenameFromResponse(response) {
    const contentDispositionHeader = response?.headers['content-disposition']
    if (!contentDispositionHeader) {
      return null
    }

    const matches = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i)
    if (!matches || !matches[1]) {
      return null
    }

    let filename = matches[1].replace(/['"]/g, '')
    filename = filename.trim()

    return filename
  },
}
