import { BaseApi } from '@/api/base-api'
import { RESOURCE_MAINTENANCE_CERTIFICATIONS } from '@/shared/constants/resources'
import Vue from 'vue'

class MaintenanceCertificationsApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  
  edit(id, data, notificationsVisible = true) {
    return super.edit(id, data, this.resource, notificationsVisible)
  }

  deleteDocument (certification_id, id_document) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.delete(`/${this.resource}/delete_doc/${certification_id}/${id_document}`)
        Vue.$toast('Documento borrado con éxito.')
        resolve(response.data.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar el documento. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  createSaleInvoice(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${this.resource}/create-sale-invoice/${id}`)
        Vue.$toast('Factura generada con éxito.')
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al generar la factura. Por favor, inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
}

export default new MaintenanceCertificationsApi(RESOURCE_MAINTENANCE_CERTIFICATIONS)