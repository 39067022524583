const state = {
    search: null,
  }
  
  const mutations = {
    setSearch(_state, search) {
      state.search = search
    }
  }

  const getters = {
    getSearch: () => state.search,
  }

  export default {
    namespaced: true,
    state,
    mutations,
    getters,
  }
  