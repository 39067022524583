import auth from '@/middleware/auth'
import { RESOURCE_ORDERS } from '@/shared/constants/resources'

export default [
  {
    path: '/orders',
    meta: {
      middleware: [auth],
      resource: RESOURCE_ORDERS,
      headerVisible: false,
    },
    name: 'orders',
    component: () => import('@/views/orders/List.vue'),
  },
  {
    path: '/orders/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_ORDERS,
      navActiveLink: 'orders',
      title: 'Pedido',
    },
    name: 'viewOrder',
    component: () => import('@/views/orders/View.vue'),
  },
  {
    path: '/orders/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_ORDERS,
      navActiveLink: 'orders',
      title: 'Crear pedido',
    },
    name: 'createOrder',
    component: () => import('@/views/orders/Create.vue'),
  },
  {
    path: '/orders/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_ORDERS,
      navActiveLink: 'orders',
      title: 'Editar pedido',
    },
    name: 'editOrder',
    component: () => import('@/views/orders/Edit.vue'),
  },
]
