import Vue from 'vue'
import DownloadService from '@/shared/services/download-service'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_OFFERS } from '@/shared/constants/resources'

class OffersApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  edit(id, data, notificationsVisible = true) {
    return super.edit(id, data, this.resource, notificationsVisible)
  }

  duplicate(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/duplicate/${id}`)
        Vue.$toast('Oferta duplicada con éxito.')
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al duplicar la oferta. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  check(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/revision/${id}`)
        Vue.$toast('Revisión efectuada con éxito')
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Error al efectuar la revisión. Por favor, inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  createJob(id, params) {
    return Vue.prototype.$http.post(`${this.resource}/create-job/${id}`, params)
  }

  createJobAditionals(id, params) {
    return Vue.prototype.$http.post(`${this.resource}/create-job-additionals/${id}`, params)
  }

  async printOffer(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/print-offer/${id}`, null, { responseType: 'blob' })
        const filename = DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(response.data, filename)
        resolve()
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al generar el PDF. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
}

export default new OffersApi(RESOURCE_OFFERS)
