/* eslint-disable no-shadow,no-param-reassign */
import OffersApi from '@/api/offers-api'

const state = {
  offer: null,
  filters: {},
  search: null,
}

const mutations = {
  setOffer(_state, offer) {
    state.offer = offer
  },
  setOfferDocumentation(_state, documentation) {
    state.offer.documentation = documentation
  },
  setFilters(_state, filters) {
    state.filters = filters
  },
  setSearch(_state, search) {
    state.search = search
  }
}

const actions = {
  loadOffer({ commit }, offerId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await OffersApi.get(offerId)
        commit('setOffer', response.data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setOfferDocumentation({ commit, state }, documentation) {
    if (!state.offer) {
      return
    }

    commit('setOfferDocumentation', documentation)
  },
}

const getters = {
  getOffer: () => state.offer,
  getGroupedOfferLines: () => {
    if (!state.offer) {
      return []
    }

    return state.offer.lines_grouped
  },
  getFilters: () => state.filters,
  getIsMaintenance: () => state.offer?.division?.group === 'M',
  getSearch: () => state.search,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
