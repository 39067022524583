import authApi from '@/api/auth-api'
import router from '@/router'
import NavigationItems from '@/navigation/vertical/index'
import { RESOURCE_ACTION_ALL } from '@/shared/constants/resourceActions'

const REMEMBER_USER_LOCAL_STORAGE_KEY = 'rememberUser'

const state = {
  loginFailed: false,
  user: null,
  token: {},
  rememberUser: null,
}

const mutations = {
  recoverUser() {
    const recoveredUser = JSON.parse(localStorage.getItem('userData'))
    if (!recoveredUser) {
      return
    }

    state.status = { loggedIn: true }
    state.user = recoveredUser
  },
  header(_, val) {
    state.header = val
  },
  logout() {
    state.status = {}
    state.token = {}
    state.user = null
  },
  refreshTokenSuccess(_, responseData) {
    const recoveredToken = JSON.parse(localStorage.getItem('token'))
    recoveredToken.token = responseData.access_token
    recoveredToken.expires_in = new Date(new Date().getTime() + responseData.expires_in * 1000).getTime()
    localStorage.setItem('token', JSON.stringify(recoveredToken))
  },
  loginSuccess(_, responseData) {
    state.status = { loggedIn: true }
    state.token = {
      token: responseData.access_token,
      token_type: responseData.token_type,
      expires_in: new Date(new Date().getTime() + responseData.expires_in * 1000).getTime(),
    }

    state.user = { ...responseData.user_data }
    state.loginFailed = false

    localStorage.setItem('userData', JSON.stringify(state.user))
    localStorage.setItem('token', JSON.stringify(state.token))
  },
  loginFailure() {
    state.status = {}
    state.token = {}
    state.user = null
    state.loginFailed = true
  },
  setRememberUser(_, rememberUser) {
    state.rememberUser = rememberUser
  },
  setUser(_, user) {
    state.user = user
    localStorage.setItem('userData', JSON.stringify(state.user))
  },
  setToken(_, token) {
    state.token = token
    localStorage.setItem('token', JSON.stringify(state.token))
  },
}

const actions = {
  async login({ commit, dispatch, getters }, { email, password, rememberMe }) {
    commit('app/loading', true, { root: true })
    try {
      const response = await authApi.login(email, password)
      if (!response.data.user_data.roles) {
        commit('notifications', { title: 'No tienes los permisos necesarios para acceder a la plataforma.', variant: 'danger' }, { root: true })
        location.reload()

        return
      }

      const rememberUser = rememberMe ? { email, password, rememberMe } : null
      dispatch('saveRememberUser', rememberUser)
      commit('loginSuccess', response.data)
      const navigationItem = NavigationItems.find(item => {
        const resource = item.resource || item.route
        return getters.canAccessResource(resource, RESOURCE_ACTION_ALL)
      })

      router.push({ name: navigationItem?.route || 'profile' }, () => {
        commit('app/loading', false, { root: true })
      })
    } catch (error) {
      commit('loginFailure', error)
      commit('app/loading', false, { root: true })
      commit('notifications', { title: 'No ha sido posible loguear. Compruebe las credenciales.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
      console.error(error)
    }
  },
  recoverPassword({ commit }, { email }) {
    commit('app/loading', true, { root: true })
    authApi.recoverPassword(email)
      .then(
        () => {
          commit('notifications', { title: 'Hemos enviado un email con las instrucciones. Revise su bandeja de entrada.', variant: 'success' }, { root: true })
          commit('app/loading', false, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
        () => {
          commit('notifications', { title: 'No ha sido posible enviar un email.', variant: 'danger' }, { root: true })
          commit('app/loading', false, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  resetPassword({ commit }, {
    email, password, password_confirmation, token,
  }) {
    commit('app/loading', true, { root: true })
    authApi.resetPassword(email, password, password_confirmation, token)
      .then(
        () => {
          commit('notifications', { title: 'Contraseña actualizada correctamente.', variant: 'success' }, { root: true })
          router.push({ name: 'login' }, () => {
            commit('app/loading', false, { root: true })
            commit('setShowNotifications', true, { root: true })
          })
        },
        () => {
          commit('notifications', { title: 'No ha sido posible actualizar su contraseña.', variant: 'danger' }, { root: true })
          commit('app/loading', false, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  clearAuthData({ commit }) {
    localStorage.removeItem('userData')
    localStorage.removeItem('token')
    commit('logout')
  },
  logout({ dispatch }) {
    dispatch('clearAuthData')
    router.push({ name: 'login' })
  },
  isUserLoggedIn() {
    const user = JSON.parse(localStorage.getItem('userData'))
    const token = JSON.parse(localStorage.getItem('token'))

    return user && token
  },
  isTokenExpired({ dispatch }) {
    const token = JSON.parse(localStorage.getItem('token'))
    const current = new Date().getTime()
    if (!token || current >= token.expires_in) {
      return true
    }

    if ((token.expires_in - current) < 1800000) {
      dispatch('refreshToken')
    }

    return false
  },
  refreshToken({ commit }) {
    authApi.refreshToken().then(response => {
      commit('refreshTokenSuccess', response.data)
    })
  },
  saveRememberUser({ commit }, rememberUser) {
    commit('setRememberUser', rememberUser)
    if (!rememberUser) {
      localStorage.removeItem(REMEMBER_USER_LOCAL_STORAGE_KEY)

      return
    }

    localStorage.setItem(REMEMBER_USER_LOCAL_STORAGE_KEY, JSON.stringify(rememberUser))
  },
  loadRememberUser({ commit }) {
    commit('setRememberUser', JSON.parse(localStorage.getItem(REMEMBER_USER_LOCAL_STORAGE_KEY)))
  },
  setUser({ commit }, user) {
    commit('setUser', user)
  },
  setToken({ commit }, token) {
    commit('setToken', token)
  },
}

const getters = {
  getUser: () => state.user,
  getRememberUser: () => state.rememberUser,
  getRole: () => {
    if (state.user?.roles[0]) {
      return state.user.roles[0].name
    }

    return ''
  },
  canAccessResource: () => (resource, resourceAction = 'view') => {
    if (!resource || resource === '') {
      return false
    }

    const capabilities = state.user?.roles[0]?.capabilities || null
    if (!capabilities) {
      return false
    }

    const computedResource = resource.replace('-', '_')
    const capability = capabilities.find(item => item.resource === computedResource)
    if (!capability) {
      return true
    }

    return capability[resourceAction] === 1
  },
  getAvatar: () => {
    if (state.user?.avatar) {
      return state.user.avatar
    }

    return process.env.VUE_APP_IMAGE_PLACEHOLDER
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
