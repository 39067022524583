import Vue from 'vue'
import DownloadService from '@/shared/services/download-service'

export class BaseApi {
  constructor(resource) {
    this.resource = resource
  }

  get(id, resource = this.resource) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get(`/${resource}/${id}`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso. Por favor inténtelo de nuevo más tarde.')
        console.error(error)
        reject(error)
      }
    })
  }

  list({
    page, per_page, orderBy, filters, search,
  }, resource = this.resource) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${resource}/list`, {
          page,
          per_page,
          ...filters,
          search,
          orderBy,
        })
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el listado de recursos. Por favor inténtelo de nuevo más tarde.')
        console.error(error)
        reject(error)
      }
    })
  }

  edit(id, data, resource = this.resource, notificationsVisible = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${resource}/${id}`, data)
        resolve(response.data)
        if (notificationsVisible) {
          Vue.$toast('Recurso actualizado con éxito.')
        }
      } catch (error) {
        if (notificationsVisible) {
          console.error(error)
          Vue.$toast.error('Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo más tarde.')
        }
        reject(error)
      }
    })
  }

  create(data, resource = this.resource, notificationsVisible = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${resource}`, data)
        resolve(response.data)
        if (notificationsVisible) {
          Vue.$toast('Recurso creado con éxito.')
        }
      } catch (error) {
        if (notificationsVisible) {
          Vue.$toast.error('Ocurrió un error al crear el recurso. Por favor inténtelo de nuevo más tarde.')
        }
        reject(error)
      }
    })
  }

  delete(id, resource = this.resource) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.delete(`/${resource}/${id}`)
        resolve(response.data)
        Vue.$toast('Recurso eliminado con éxito.')
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar el recurso. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }

  deleteFile(resourceId, fileId, resource = this.resource) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.delete(`${resource}/delete_doc/${resourceId}/${fileId}`)
        resolve(response.data)
        Vue.$toast('Documento eliminado con éxito.')
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar el documento. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }

  deleteFiles(resourceId, files, resource = this.resource) {
    return new Promise(async (resolve, reject) => {
      try {
        const promises = files.map(file => Vue.prototype.$http.delete(`${resource}/delete_doc/${resourceId}/${file.id}`))
        await Promise.all(promises)
        resolve()
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar los documentos. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }

  deleteImage(id, resource = this.resource) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.delete(`/${resource}/delete_image/${id}`)
        resolve(response.data)
        Vue.$toast('Imagen eliminada con éxito.')
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar la imagen. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }

  downloadArchive(id, name = null) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get(`/archives/download/${id}`, { responseType: 'blob' })
        const filename = name || DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(new Blob([response.data]), filename)
        resolve()
      } catch (error) {
        Vue.$toast.error('Error al descargar el archivo. Por favor inténtelo de nuevo más tarde.')
        console.error(error)
        reject(error)
      }
    })
  }
}

const ApiRestService = new BaseApi('')
export default ApiRestService
