import auth from '@/middleware/auth'
import { RESOURCE_DELIVERY_NOTES } from '@/shared/constants/resources'

export default
[
  {
    path: '/delivery-notes',
    meta: {
      middleware: [auth],
      resource: RESOURCE_DELIVERY_NOTES,
      headerVisible: false,
    },
    name: 'deliveryNotes',
    component: () => import('@/views/delivery-notes/List.vue'),
  },
  {
    path: '/delivery-notes/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_DELIVERY_NOTES,
      navActiveLink: 'deliveryNotes',
      title: 'Albaran',
    },
    name: 'viewDeliveryNotes',
    component: () => import('@/views/delivery-notes/View.vue'),
  },
  {
    path: '/delivery-notes/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_DELIVERY_NOTES,
      navActiveLink: 'deliveryNotes',
      title: 'Crear albaran',
    },
    name: 'createDeliveryNotes',
    component: () => import('@/views/delivery-notes/Create.vue'),
  },
  {
    path: '/delivery-notes/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_DELIVERY_NOTES,
      navActiveLink: 'deliveryNotes',
      title: 'Editar albaran',
    },
    name: 'editDeliveryNotes',
    component: () => import('@/views/delivery-notes/Edit.vue'),
  },
]
