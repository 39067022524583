const state = {
  filters: {},
  search: null,
}


const mutations = {
setFilters(_state, filters) {
  state.filters = filters
},
setSearch(_state, search) {
  state.search = search
}
}

const getters = {
getFilters: () => state.filters,
getSearch: () => state.search,
}

export default {
namespaced: true,
state,
mutations,
getters,
}
