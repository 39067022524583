import axios from 'axios'

axios.interceptors.request.use(request => {
  const token = JSON.parse(localStorage.getItem('token'))

  if (token && token.token) {
    request.headers.Authorization = `${token.token_type} ${token.token}`
  }
  return request
}, err => Promise.reject(err))
