import Vue from 'vue'
import axios from 'axios'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_PRODUCT_CATEGORIES } from '@/shared/constants/resources'

class CategoriesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  setCategoryParent(categoryId, categoryParentId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/setparent/${categoryId}`, { parent_id: categoryParentId })
        resolve(response.data)
        Vue.$toast('Categorías actualizadas con éxito')
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al actualizar las categorías. Por favor inténtelo de nuevo mas tarde')
        console.error(error)
        reject(error)
      }
    })
  }

  async setCategoryOrder(categoryIds) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/${this.resource}/reorder`, { rows: categoryIds })
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al actualizar las categorías. Por favor inténtelo de nuevo mas tarde')
        console.error(error)
        reject(error)
      }
    })
  }

  getCategoryGroups() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get('/category-groups')
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso. Por favor inténtelo de nuevo mas tarde')
        console.error(error)
        reject(error)
      }
    })
  }
}

export default new CategoriesApi(RESOURCE_PRODUCT_CATEGORIES)
