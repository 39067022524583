import auth from '@/middleware/auth'
import { RESOURCE_USERS } from '@/shared/constants/resources'

export default [
  {
    path: '/users',
    meta: {
      middleware: [auth],
      resource: RESOURCE_USERS,
      headerVisible: false,
    },
    name: 'users',
    component: () => import('@/views/users/List.vue'),
  },
  {
    path: '/users/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_USERS,
      navActiveLink: 'users',
      title: 'Editar usuario',
    },
    name: 'editUser',
    component: () => import('@/views/users/Edit.vue'),
  },
  {
    path: '/users/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_USERS,
      navActiveLink: 'users',
      title: 'Usuario',
    },
    name: 'viewUser',
    component: () => import('@/views/users/View.vue'),
  },
  {
    path: '/users/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_USERS,
      navActiveLink: 'users',
      title: 'Crear usuario',
    },
    name: 'createUser',
    component: () => import('@/views/users/Create.vue'),
  },
]
