import auth from '@/middleware/auth'
import { RESOURCE_SALE_INVOICES } from '@/shared/constants/resources'

export default
[
  {
    path: '/sale-invoices',
    meta: {
      middleware: [auth],
      resource: RESOURCE_SALE_INVOICES,
      headerVisible: false,
    },
    name: 'saleInvoices',
    component: () => import('@/views/sale-invoices/List.vue'),
  },
  {
    path: '/sale-invoices/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_SALE_INVOICES,
      navActiveLink: 'saleInvoices',
      title: 'Factura',
    },
    name: 'viewSaleInvoice',
    component: () => import('@/views/sale-invoices/View.vue'),
  },
  {
    path: '/sale-invoices/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_SALE_INVOICES,
      navActiveLink: 'saleInvoices',
      title: 'Crear factura',
    },
    name: 'createSaleInvoice',
    component: () => import('@/views/sale-invoices/Create.vue'),
  },
  {
    path: '/sale-invoices/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_SALE_INVOICES,
      navActiveLink: 'saleInvoices',
      title: 'Editar factura',
    },
    name: 'editSaleInvoice',
    component: () => import('@/views/sale-invoices/Edit.vue'),
  },
  {
    path: '/sale-invoices/reports',
    meta: {
      middleware: [auth],
      resource: RESOURCE_SALE_INVOICES,
      navActiveLink: 'saleInvoicesReport',
      title: 'Informe facturas pendientes de cobro',
    },
    name: 'saleInvoiceReports',
    component: () => import('@/views/sale-invoices-reports/List.vue'),
  },
]
