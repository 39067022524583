import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import login from '@/middleware/login'
import UserRoutes from '@/router/user-routes'
import ClientRoutes from '@/router/client-routes'
import ProjectRoutes from '@/router/project-routes'
import ContactRoutes from '@/router/contact-routes'
import ProviderRoutes from '@/router/provider-routes'
import OfferRoutes from '@/router/offer-routes'
import ProductRoutes from '@/router/product-routes'
import CategoryRoutes from '@/router/category-routes'
import SaleInvoicesRoutes from '@/router/sale-invoices-routes'
import PurchaseInvoicesRoutes from '@/router/purchase-invoices-routes'
import OrderRoutes from '@/router/order-routes'
import DeliveryNotesRoutes from '@/router/delivery-notes-routes'
import JobRoutes from '@/router/job-routes'
import CertificationRoutes from '@/router/certification-routes'
import NonConformitiesRoutes from '@/router/non-conformities-routes'
import ConfigurationRoutes from '@/router/configuration-routes'
import RoleRoutes from '@/router/role-routes'
import ProfileRoutes from '@/router/profile-routes'
import ReportsRoutes from '@/router/reports-routes'
import MaintenanceCertifications from  '@/router/maintenance-certifications'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' }, meta: { layout: 'full' } },
    ...UserRoutes,
    ...ClientRoutes,
    ...ProjectRoutes,
    ...ContactRoutes,
    ...ProviderRoutes,
    ...OfferRoutes,
    ...ProductRoutes,
    ...CategoryRoutes,
    ...SaleInvoicesRoutes,
    ...PurchaseInvoicesRoutes,
    ...OrderRoutes,
    ...DeliveryNotesRoutes,
    ...JobRoutes,
    ...CertificationRoutes,
    ...NonConformitiesRoutes,
    ...ConfigurationRoutes,
    ...RoleRoutes,
    ...ProfileRoutes,
    ...ReportsRoutes,
    ...MaintenanceCertifications,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login.vue'),
      meta: {
        layout: 'full',
        middleware: [login],
      },
    },
    {
      path: '/reset-password/:token/:email',
      name: 'reset-password',
      component: () => import('@/views/users/ResetPassword.vue'),
      meta: {
        layout: 'full',
        middleware: [login],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        middleware: [login],
      },
    },
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        middleware: [login],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
      store,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
