import auth from '@/middleware/auth'
import { RESOURCE_CLIENTS } from '@/shared/constants/resources'

export default
[
  {
    path: '/clients',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CLIENTS,
      headerVisible: false,
    },
    name: 'clients',
    component: () => import('@/views/clients/List.vue'),
  },
  {
    path: '/clients/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CLIENTS,
      navActiveLink: 'clients',
      title: 'Cliente',
    },
    name: 'viewClient',
    component: () => import('@/views/clients/View.vue'),
  },
  {
    path: '/clients/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CLIENTS,
      navActiveLink: 'clients',
      title: 'Crear cliente',
    },
    name: 'createClient',
    component: () => import('@/views/clients/Create.vue'),
  },
  {
    path: '/clients/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CLIENTS,
      navActiveLink: 'clients',
      title: 'Editar cliente',
    },
    name: 'editClient',
    component: () => import('@/views/clients/Edit.vue'),
  },
]
