/* eslint-disable no-shadow,no-param-reassign,consistent-return */
import PurchaseInvoicesApi from '@/api/purchase-invoices-api'

const state = {
  purchaseInvoice: null,
  purchaseInvoiceLines: [],
  filters: {},
  tabSelected: localStorage.getItem('purchaseInvoicesTabSelected') ? JSON.parse(localStorage.getItem('purchaseInvoicesTabSelected')) : 0,
  search: null,
}

const mutations = {
  setPurchaseInvoice(_state, purchaseInvoice) {
    state.purchaseInvoice = purchaseInvoice
  },
  setPurchaseInvoiceLines(_state, purchaseInvoiceLines) {
    state.purchaseInvoiceLines = purchaseInvoiceLines
  },
  setPurchaseInvoiceTax(_state, purchaseInvoiceTax) {
    state.purchaseInvoice.tax = purchaseInvoiceTax
  },
  setPurchaseInvoiceDocumentation(_state, documentation) {
    state.purchaseInvoice.documentation = documentation
  },
  setFilters(_state, filters) {
    state.filters = filters
  },
  setTabSelected(_state, value) {
    state.tabSelected = value
    localStorage.setItem('purchaseInvoicesTabSelected', value)
  },
  setSearch(_state, search) {
    state.search = search
  }
}

const actions = {
  loadPurchaseInvoice({ commit }, purchaseInvoiceId) {
    if (!purchaseInvoiceId) {
      return
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await PurchaseInvoicesApi.get(purchaseInvoiceId)
        commit('setPurchaseInvoice', response.data)
        commit('setPurchaseInvoiceLines', response.data.lines)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setPurchaseInvoice({ commit }, purchaseInvoice) {
    if (!purchaseInvoice) {
      return
    }

    commit('setPurchaseInvoice', purchaseInvoice)
  },
  setPurchaseInvoiceTax({ commit }, purchaseInvoiceTax) {
    if (!state.purchaseInvoice) {
      return
    }

    commit('setPurchaseInvoiceTax', purchaseInvoiceTax)
  },
  setPurchaseInvoiceDocumentation({ commit, state }, documentation) {
    if (!state.purchaseInvoice) {
      return
    }

    commit('setPurchaseInvoiceDocumentation', documentation)
  },
}

const getters = {
  getPurchaseInvoice: () => state.purchaseInvoice,
  getPurchaseInvoiceLines: () => state.purchaseInvoiceLines,
  getFilters: () => state.filters,
  getTabSelected: () => state.tabSelected,
  getSearch: () => state.search,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
