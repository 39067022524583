import auth from '@/middleware/auth'
import { RESOURCE_OFFERS } from '@/shared/constants/resources'

export default [
  {
    path: '/offers',
    meta: {
      middleware: [auth],
      resource: RESOURCE_OFFERS,
      headerVisible: false,
    },
    name: 'offers',
    component: () => import('@/views/offers/List.vue'),
  },
  {
    path: '/offers/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_OFFERS,
      navActiveLink: 'offers',
      title: 'Oferta',
    },
    name: 'viewOffer',
    component: () => import('@/views/offers/View.vue'),
  },
  {
    path: '/offers/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_OFFERS,
      navActiveLink: 'offers',
      title: 'Crear oferta',
    },
    name: 'createOffer',
    component: () => import('@/views/offers/Create.vue'),
  },
  {
    path: '/offers/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_OFFERS,
      navActiveLink: 'offers',
      title: 'Editar oferta',
    },
    name: 'editOffer',
    component: () => import('@/views/offers/Edit.vue'),
  },
  {
    path: '/offers/rater/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_OFFERS,
      navActiveLink: 'offers',
      title: 'Tarificador de oferta',
    },
    name: 'raterOffer',
    component: () => import('@/views/offers/Rater.vue'),
  },
]
