import auth from '@/middleware/auth'
import { RESOURCE_CONFIGURATION } from '@/shared/constants/resources'

export default [
  {
    path: '/roles',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      headerVisible: false,
    },
    name: 'roles',
    component: () => import('@/views/roles/List.vue'),
  },
  {
    path: '/roles/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      navActiveLink: 'roles',
      title: 'Rol',
    },
    name: 'viewRole',
    component: () => import('@/views/roles/View.vue'),
  },
  {
    path: '/roles/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      navActiveLink: 'roles',
      title: 'Crear rol',
    },
    name: 'createRole',
    component: () => import('@/views/roles/Create.vue'),
  },
]
