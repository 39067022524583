import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import DownloadService from '@/shared/services/download-service'
import { RESOURCE_ORDERS } from '@/shared/constants/resources'

class OrdersApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  async printOrder(orderId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/print-order/${orderId}`, {}, { responseType: 'blob' })
        const filename = DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(response.data, filename)
        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
        Vue.$toast.error('Error al descargar el pedido. Por favor inténtelo de nuevo mas tarde.')
      }
    })
  }
}

export default new OrdersApi(RESOURCE_ORDERS)
