import * as RESOURCES from '@/shared/constants/resources'
import * as RESOURCE_ACTIONS from '@/shared/constants/resourceActions'

export default {
  data() {
    return {
      $constants: {
        RESOURCES,
        RESOURCE_ACTIONS,
      },
    }
  },
}
