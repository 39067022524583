import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_JOBS_CONTRACTS } from '@/shared/constants/resources'

class JobContractsApi extends BaseApi {
    constructor(resource) {
      super(resource)
      this.resource = resource
    }
    list (job_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Vue.prototype.$http.post(`/${this.resource}/list`, { job_id })
          resolve(response.data)
        } catch (error) {
          Vue.$toast.error('Ocurrió un error al obtener la información del job. Por favor, inténtelo de nuevo mas tarde.')
          reject(error)
        }
      })
    }
    getDaysEndActiveContract (id) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Vue.prototype.$http.post(`/${this.resource}/days-end-active-contract/${id}`)
          resolve(response.data)
        } catch (error) {
          Vue.$toast.error('Ocurrió un error al obtener la información del job. Por favor, inténtelo de nuevo mas tarde.')
          reject(error)
        }
      })
    }
    renewContract (id, body) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Vue.prototype.$http.post(`/${this.resource}/renew/${id}`, body)
          resolve(response.data)
        } catch (error) {
          Vue.$toast.error('Ocurrió un error al actualizar la información del job. Por favor, inténtelo de nuevo mas tarde.')
          reject(error)
        }
      })
    }
    renewOfferContract (id, body) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Vue.prototype.$http.post(`/${this.resource}/renew-offer/${id}`, body)
          resolve(response.data)
        } catch (error) {
          Vue.$toast.error('Ocurrió un error al actualizar la información del job. Por favor, inténtelo de nuevo mas tarde.')
          reject(error)
        }
      })
    }
    updateContract (id, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Vue.prototype.$http.post(`/${this.resource}/${id}`, params)
          resolve(response.data)
        } catch (error) {
          if (error.response?.data?.error) {
            Vue.$toast.error(error.response?.data?.error)
          } else {
            Vue.$toast.error('Ocurrió un error al actualizar la información del job. Por favor, inténtelo de nuevo mas tarde.')
          }
          reject(error)
        }
      })
    }
    getContract(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Vue.prototype.$http.get(`/${this.resource}/${id}`)
          resolve(response.data)
        } catch (error) {
          if (error.response?.data?.error) {
            Vue.$toast.error(error.response?.data?.error)
          } else {
            Vue.$toast.error('Ocurrió un error al actualizar la información del job. Por favor, inténtelo de nuevo mas tarde.')
          }
          reject(error)
        }
      })
    }
}

export default new JobContractsApi(RESOURCE_JOBS_CONTRACTS)