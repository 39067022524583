import auth from '@/middleware/auth'
import { RESOURCE_CATEGORIES } from '@/shared/constants/resources'

export default
[
  {
    path: '/categories',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CATEGORIES,
      headerVisible: false,
    },
    name: 'categories',
    component: () => import('@/views/categories/List.vue'),
  },
  {
    path: '/categories/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CATEGORIES,
      navActiveLink: 'categories',
      title: 'Categoría',
    },
    name: 'viewCategory',
    component: () => import('@/views/categories/View.vue'),
  },
  {
    path: '/categories/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CATEGORIES,
      navActiveLink: 'categories',
      title: 'Crear categoría',
    },
    name: 'createCategory',
    component: () => import('@/views/categories/Create.vue'),
  },
  {
    path: '/categories/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CATEGORIES,
      navActiveLink: 'categories',
      title: 'Editar categoría',
    },
    name: 'editCategory',
    component: () => import('@/views/categories/Edit.vue'),
  },
]
