/* eslint-disable no-shadow,no-param-reassign,consistent-return */
import SaleInvoicesApi from '@/api/sale-invoices-api'
import ApiRestService from '@/api/base-api'
import { RESOURCE_SALE_INVOICE_LINES } from '@/shared/constants/resources'

const state = {
  saleInvoice: null,
  saleInvoiceLines: [],
  saleInvoicesFilters: {},
  retentionFilters: {},
  search: null,
  tabSelected: 0,
}

const mutations = {
  setSaleInvoice(_state, saleInvoice) {
    state.saleInvoice = saleInvoice
  },
  setSaleInvoiceLines(_state, saleInvoiceLines) {
    state.saleInvoiceLines = saleInvoiceLines
  },
  setSaleInvoiceTax(_state, saleInvoiceTax) {
    state.saleInvoice.tax = saleInvoiceTax
  },
  setSaleInvoiceDocumentation(_state, documentation) {
    state.saleInvoice.documentation = documentation
  },
  setSaleInvoicesFilters(_state, filters) {
    state.saleInvoicesFilters = filters
  },
  setRetentionFilters(_state, filters) {
    state.retentionFilters = filters
  },
  setTabSelected(_state, value) {
    state.tabSelected = value
  },
  setSearch(_state, search) {
    state.search = search
  }
}

const actions = {
  loadSaleInvoice({ commit }, saleInvoiceId) {
    if (!saleInvoiceId) {
      return
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await Promise.all([
          SaleInvoicesApi.get(saleInvoiceId),
          ApiRestService.list({ filters: { sale_invoice_id: saleInvoiceId } }, RESOURCE_SALE_INVOICE_LINES),
        ])
        commit('setSaleInvoice', response[0].data)
        commit('setSaleInvoiceLines', response[1].data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setSaleInvoice({ commit }, saleInvoice) {
    if (!saleInvoice) {
      return
    }

    commit('setSaleInvoice', saleInvoice)
  },
  setSaleInvoiceTax({ commit }, saleInvoiceTax) {
    if (!state.saleInvoice) {
      return
    }

    commit('setSaleInvoiceTax', saleInvoiceTax)
  },
  setSaleInvoiceDocumentation({ commit, state }, documentation) {
    if (!state.saleInvoice) {
      return
    }

    commit('setSaleInvoiceDocumentation', documentation)
  },
}

const getters = {
  getSaleInvoice: () => state.saleInvoice,
  getSaleInvoiceLines: () => state.saleInvoiceLines,
  getSaleInvoicesFilters: () => state.saleInvoicesFilters,
  getRetentionFilters: () => state.retentionFilters,
  getTabSelected: () => state.tabSelected,
  getSearch: () => state.search,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
