import * as RESOURCES from '@/shared/constants/resources'

export default [
  {
    header: 'JOBS',
  },
  {
    title: 'Jobs',
    icon: 'BriefcaseIcon',
    route: 'jobs',
  },
  {
    header: 'FINANZAS',
  },
  {
    title: 'Ofertas',
    route: 'offers',
    icon: 'FileTextIcon',
  },
  {
    title: 'Facturas de venta',
    route: 'saleInvoices',
    resource: RESOURCES.RESOURCE_SALE_INVOICES,
    icon: 'CreditCardIcon',
  },
  {
    title: 'Pedidos',
    route: 'orders',
    icon: 'FileIcon',
  },
  {
    title: 'Facturas proveedores',
    route: 'purchaseInvoices',
    resource: RESOURCES.RESOURCE_PURCHASE_INVOICES,
    icon: 'InboxIcon',
  },
  {
    title: 'Certificaciones',
    icon: 'PercentIcon',
    route: 'certifications',
    resource: RESOURCES.RESOURCE_CERTIFICATIONS,
  },
  {
    title: 'Certificaciones Mantenimiento',
    icon: 'PercentIcon',
    route: 'maintenanceCertifications',
    resource: RESOURCES.RESOURCE_MAINTENANCE_CERTIFICATIONS,
  },
  {
    title: 'Albaranes',
    icon: 'FolderIcon',
    route: 'deliveryNotes',
    resource: RESOURCES.RESOURCE_DELIVERY_NOTES,
  },
  {
    title: 'No conformidades',
    icon: 'AlertOctagonIcon',
    route: 'nonConformities',
    resource: RESOURCES.RESOURCE_NON_CONFORMITIES,
  },
  {
    header: 'BBDD ENTIDADES',
  },
  {
    title: 'Clientes',
    route: 'clients',
    icon: 'UsersIcon',
  },
  {
    title: 'Obras',
    route: 'projects',
    icon: 'HomeIcon',
  },
  {
    title: 'Proveedores',
    route: 'providers',
    icon: 'ArchiveIcon',
  },
  {
    header: 'MANTENIMIENTO',
  },
  {
    title: 'Mantenimientos',
    icon: 'ToolIcon',
    disabled: true,
  },
  {
    title: 'Equipos',
    icon: 'BoxIcon',
    disabled: true,
  },
  {
    title: 'Etiquetas',
    icon: 'GridIcon',
    disabled: true,
  },
  {
    header: 'BBDD PRODUCTOS',
  },
  {
    title: 'Productos',
    route: 'products',
    icon: 'StopCircleIcon',
  },
  {
    title: 'Categorías de productos',
    route: 'categories',
    icon: 'ListIcon',
  },
  {
    header: 'INFORMES',
  },
  {
    title: 'Certificaciones',
    route: 'certificationReports',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Cartera contracting',
    route: 'jobReports',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Cartera service',
    route: 'jobReportsServices',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Facturas pend. pago',
    route: 'purchaseInvoicesReports',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Facturas pend. cobro',
    route: 'saleInvoiceReports',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Límites financieros',
    route: 'financialLimitReports',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Informe de pedidos/ventas',
    route: 'salesOrdersReports',
    icon: 'PieChartIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    header: 'CONFIGURACIÓN',
  },
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UserIcon',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Roles',
    icon: 'CheckSquareIcon',
    route: 'roles',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    route: 'configuration',
    resource: RESOURCES.RESOURCE_CONFIGURATION,
  },
]
