import OrdersApi from '@/api/orders-api'

const state = {
  order: null,
  filters: null,
  search: null,
}

const mutations = {
  setOrder(_state, order) {
    state.order = order
  },
  setOrderDocumentation(_state, documentation) {
    state.order.documentation = documentation
  },
  setOrderTax(_state, orderTax) {
    state.order.tax = orderTax
  },
  setFilters(_state, filters) {
    state.filters = filters
  },
  setSearch(_state, search) {
    state.search = search
  }
}

const actions = {
  loadOrder({ commit }, orderId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await OrdersApi.get(orderId)
        commit('setOrder', response.data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setOrderDocumentation({ commit }, documentation) {
    if (!state.order) {
      return
    }

    commit('setOrderDocumentation', documentation)
  },
  setOrderTax({ commit }, orderTax) {
    if (!state.order) {
      return
    }

    commit('setOrderTax', orderTax)
  },
  setOrder({ commit }, order) {
    commit('setOrder', order)
  },
}

const getters = {
  getOrder: () => state.order,
  getOrderLines: () => state.order?.lines || [],
  getFilters: () => state.filters,
  getSearch: () => state.search,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
