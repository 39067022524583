import JobsApi, { 
    JOB_INFO_TYPE_ECONOMIC_STATE, 
    JOB_INFO_TYPE_ORDERS, 
    JOB_INFO_TYPE_PURCHASE_INVOICES,
} from '@/api/jobs-api'
import JobExpectedCostLinesApi from '@/api/job-expected-cost-lines-api'
import JobContractsApi from '@/api/job-contracts-api'
import ApiRestService from '@/api/base-api'
import { RESOURCE_JOBS_ADDITIONAL_LINES } from '@/shared/constants/resources'
import Vue from 'vue'

const state = {
  job: null,
  jobInfo: {
    economicState: null,
    orders: [],
    purchaseInvoices: [],
  },
  jobAdditionalLines: [],
  jobAdditionalLinesTotals: {},
  jobExpectedCostLines: [],
  jobExpectedCostLinesTotals: {},
  daysEndActiveContract: null,
  lastOpenedTab: null,
  lastLoadedJobId: null,
  contractsSelected: localStorage.getItem("contractsSelected") ? JSON.parse(localStorage.getItem("contractsSelected")) : [],
  filters: {},
  search: null,
  rowsEditionEnabled: []
}

const mutations = {
  setJob(_, job) {
    state.job = job
  },
  setJobDocumentation(_, documentation) {
    state.job.documentation = documentation
  },
  setEconomicState(_, economicState) {
    state.jobInfo.economicState = economicState
  },
  setOrders(_, orders) {
    state.jobInfo.orders = orders
  },
  setPurchaseInvoices(_, purchaseInvoices) {
    state.jobInfo.purchaseInvoices = purchaseInvoices
  },
  setJobAdditionalLines(_, jobAdditionalLines) {
    state.jobAdditionalLines = jobAdditionalLines
  },
  setJobAdditionalLinesTotals(_, jobAdditionalLinesTotals) {
    state.jobAdditionalLinesTotals = jobAdditionalLinesTotals
  },
  setJobExpectedCostLines(_, jobExpectedCostLines) {
    state.jobExpectedCostLines = jobExpectedCostLines
  },
  setJobExpectedCostLinesTotals(_, jobExpectedCostLinesTotals) {
    state.jobExpectedCostLinesTotals = jobExpectedCostLinesTotals
  },
  setLastOpenedTab(_, lastOpenedTab) {
    state.lastOpenedTab = lastOpenedTab
  },
  setLastLoadedJobId(_, lastLoadedJobId) {
    state.lastLoadedJobId = lastLoadedJobId
  },
  setContractsSelected(_, contractsSelected) {
    state.contractsSelected = contractsSelected.map((contract) => {
      contract.job_id = parseInt(contract.job_id)
      return contract
    })
    localStorage.setItem("contractsSelected", JSON.stringify(contractsSelected))
  },
  setDaysEndActiveContract(_, days) {
    state.daysEndActiveContract = days
  },
  setFilters(_state, filters) {
    state.filters = filters
  },
  setSearch(_state, search) {
    state.search = search
  },
  setRowsEditionEnabled(_state, row) {
    Vue.set(state.rowsEditionEnabled, row.index, row.value)
  }
}

const actions = {
  loadJob({ getters, state,commit, dispatch }, id) {
    dispatch('loadLastJobId')
    if (state.lastLoadedJobId !== id.toString()) {
      dispatch('setLastOpenedTab', 0)
      dispatch('setLastLoadedJobId', id)
    }
    
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobsApi.get(id)
        commit('setJob', response.data)
        dispatch('loadDaysEndActiveContract', id)
        if (Object.keys(getters.getContractSelected(id)).length === 0 && getters.getIsMaintenance) {
          await dispatch('loadContractActive', id)
        }
        resolve(state.job)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadJobInfo({ dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        await Promise.all([
          dispatch('loadJobEconomicState', id),
          dispatch('loadJobOrders', id),
          dispatch('loadJobPurchaseInvoices', id),
        ])
        resolve(state.jobInfo.economicState)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadJobEconomicState({ getters, commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobsApi.getJobInfo(id, JOB_INFO_TYPE_ECONOMIC_STATE, getters.getContractSelected(id)?.contract?.id)
        commit('setEconomicState', response.data)
        resolve(state.jobInfo.economicState)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadJobOrders({ getters, commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobsApi.getJobInfo(id, JOB_INFO_TYPE_ORDERS, getters.getContractSelected(id)?.contract?.id)
        commit('setOrders', response)
        resolve(state.jobInfo.orders)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadJobPurchaseInvoices({ getters, commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobsApi.getJobInfo(id, JOB_INFO_TYPE_PURCHASE_INVOICES, getters.getContractSelected(id)?.contract?.id)
        commit('setPurchaseInvoices', response)
        resolve(state.jobInfo.purchaseInvoices)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadJobAdditionalLines({ getters, commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiRestService.list({ filters: { job_id: id, job_contract_id: getters.getContractSelected(id)?.contract?.id }, }, RESOURCE_JOBS_ADDITIONAL_LINES)
        commit('setJobAdditionalLines', response.data)
        commit('setJobAdditionalLinesTotals', response.totals)
        resolve(state.jobAdditionalLines)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadJobExpectedCostLines({ getters, commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobExpectedCostLinesApi.list({ filters: { job_id: id, job_contract_id: getters.getContractSelected(id)?.contract?.id, } })
        commit('setJobExpectedCostLines', response.data)
        commit('setJobExpectedCostLinesTotals', response.totals)
        resolve(state.jobAdditionalLines)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadDaysEndActiveContract ({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobContractsApi.getDaysEndActiveContract(id)
        commit('setDaysEndActiveContract', response?.days_end_active_contract)
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  },
  loadContractActive ({ dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobContractsApi.list(id)
        const contractActive = response.data.find(({ active }) => active === 1)
        if (contractActive) {
          await dispatch('setContractSelected', { job_id: id, contract: contractActive })
        }
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateContract({ dispatch },{ contract_id, params, job_id }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await JobContractsApi.updateContract(contract_id, params)
        const contractSelected = {
          job_id: parseInt(job_id),
          contract: response.data
        }
        await dispatch('setContractSelected', contractSelected)
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  },
  setJobDocumentation({ commit }, documentation) {
    if (!state.job) {
      return
    }

    commit('setJobDocumentation', documentation)
  },
  setJob({ commit }, job) {
    commit('setJob', job)
  },
  setLastOpenedTab({ commit }, lastOpenedTab) {
    sessionStorage.setItem('lastOpenedJobTab', lastOpenedTab)
    commit('setLastOpenedTab', lastOpenedTab)
  },
  // loadLastOpenedTab({ dispatch }) {
  //   let lastOpenedTab = sessionStorage.getItem('lastOpenedJobTab')
  //   if (!lastOpenedTab || Number.isNaN(lastOpenedTab)) {
  //     lastOpenedTab = 0
  //   }

  //   dispatch('setLastOpenedTab', Number.parseInt(lastOpenedTab, 10))
  // },
  setLastLoadedJobId({ commit }, lastLoadedJobId) {
    sessionStorage.setItem('lastLoadedJobId', lastLoadedJobId)
    commit('setLastLoadedJobId', lastLoadedJobId)
  },
  loadLastJobId({ dispatch }) {
    dispatch('setLastLoadedJobId', sessionStorage.getItem('lastLoadedJobId'))
  },
  setAdditionalLineRowVariant(_, { categoryGroupId, lineId, rowVariant }) {
    const category = state.jobAdditionalLines.find(jobAdditionalLine => jobAdditionalLine.id === categoryGroupId);
    if (!category) {
      return
    }

    const line = category.lines.find(item => item.id === lineId)
    if (!line) {
      return
    }

    Vue.set(line, '_rowVariant', rowVariant || '')
  },
  setExpectedCostLineRowVariant(_, { categoryGroupId, lineId, rowVariant }) {
    const category = state.jobExpectedCostLines.find(jobExpectedCostLine => jobExpectedCostLine.id === categoryGroupId);
    if (!category) {
      return
    }

    const line = category.lines.find(item => item.id === lineId)
    if (!line) {
      return
    }

    Vue.set(line, '_rowVariant', rowVariant || '')
  },
  setContractSelected({ state, commit }, contractSelected) {
    const exist = state.contractsSelected.some(({ job_id }) => job_id === contractSelected.job_id)
  
    if (exist) {
      const replaceContractId = state.contractsSelected.map((item) => {  
        if ( item.job_id === contractSelected.job_id ) {
          item.contract = contractSelected.contract
        }
        return item
      })

      commit('setContractsSelected', replaceContractId)
    } else {
      commit('setContractsSelected', [...state.contractsSelected, contractSelected])
    }
  }
}

const getters = {
  getJob: () => state.job,
  getJobEconomicState: () => state.jobInfo.economicState,
  getJobAdditionalLines: () => state.jobAdditionalLines,
  getJobAdditionalLinesTotals: () => state.jobAdditionalLinesTotals,
  getJobExpectedCostLines: () => state.jobExpectedCostLines,
  getJobExpectedCostLinesTotals: () => state.jobExpectedCostLinesTotals,
  getJobOrders: () => state.jobInfo.orders,
  getJobPurchaseInvoices: () => state.jobInfo.purchaseInvoices,
  getLastOpenedTab: () => state.lastOpenedTab,
  getIsMaintenance: () => state.job?.division?.group === 'M',
  getIsService: () => state.job?.division?.group === 'S',
  getContractSelected: (state) => (jobId) => {
    return state.contractsSelected.find(({ job_id }) => job_id?.toString() === jobId?.toString()) || {};
  },
  getIsReadOnlyMode: (state) => {
    return state.job?.status?.name === 'Cerrado'
  },
  getDaysEndActiveContract: (state) => state.daysEndActiveContract,
  getFilters: () => state.filters,
  getSearch: () => state.search,
  getRowsEditionEnabled: () => state.rowsEditionEnabled
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
