/* eslint-disable no-shadow,no-param-reassign */
import CategoriesApi from '@/api/categories-api'
import ProductAttrConfigurationApi from '@/api/product-attr-configuration-api'

const state = {
  category: null,
  categoryAttributes: null,
  filters: {},
  search: null,
}

const mutations = {
  setCategory(_state, category) {
    state.category = category
  },
  setCategoryAttributes(_state, categoryAttributes) {
    state.categoryAttributes = categoryAttributes
  },
  setFilters(_state, filters) {
    state.filters = filters
  },
  setSearch(_state, search) {
    state.search = search
  }
}

const actions = {
  loadCategory({ commit }, categoryId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await CategoriesApi.get(categoryId)
        commit('setCategory', response.data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  loadCategoryAttributes({ commit }, categoryId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ProductAttrConfigurationApi.list({
          filters: { product_category_id: categoryId },
        })
        commit('setCategoryAttributes', response.data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setCategoryAttributes({ commit }, categoryAttributes) {
    commit('setCategoryAttributes', categoryAttributes)
  },
}

const getters = {
  getCategory: () => state.category,
  getCategoryAttributes: () => {
    if (!state.categoryAttributes) {
      return []
    }

    return state.categoryAttributes
  },
  getFilters: () => state.filters,
  getSearch: () => state.search,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
