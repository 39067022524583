import auth from '@/middleware/auth'
import { RESOURCE_NON_CONFORMITIES } from '@/shared/constants/resources'

export default [
  {
    path: '/non-conformities',
    meta: {
      middleware: [auth],
      resource: RESOURCE_NON_CONFORMITIES,
      headerVisible: false,
    },
    name: 'nonConformities',
    component: () => import('@/views/non-conformities/List.vue'),
  },
  {
    path: '/non-conformities/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_NON_CONFORMITIES,
      navActiveLink: 'nonConformities',
      title: 'No conformidad',
    },
    name: 'viewNonConformity',
    component: () => import('@/views/non-conformities/View.vue'),
  },
  {
    path: '/non-conformities/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_NON_CONFORMITIES,
      navActiveLink: 'nonConformities',
      title: 'Crear no conformidad',
    },
    name: 'createNonConformity',
    component: () => import('@/views/non-conformities/Create.vue'),
  },
  {
    path: '/non-conformities/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_NON_CONFORMITIES,
      navActiveLink: 'nonConformities',
      title: 'Editar no conformidad',
    },
    name: 'editNonConformity',
    component: () => import('@/views/non-conformities/Edit.vue'),
  },
]
