export const RESOURCE_CATEGORIES = 'categories'
export const RESOURCE_CLIENTS = 'clients'
export const RESOURCE_CONTACTS = 'contacts'
export const RESOURCE_USERS = 'users'
export const RESOURCE_PROFILE = 'profile'
export const RESOURCE_PROVIDERS = 'providers'
export const RESOURCE_CONFIGURATION = 'contiguration'
export const RESOURCE_PROJECTS = 'projects'
export const RESOURCE_COUNTRIES = 'countries'
export const RESOURCE_DEPARTMENTS = 'departments'
export const RESOURCE_PROVINCES = 'provinces'
export const RESOURCE_BANK_ACCOUNTS = 'bank-accounts'
export const RESOURCE_ACCOUNTING_ITEMS = 'accounting-items'
export const RESOURCE_DIVISIONS = 'divisions'
export const RESOURCE_TYPE_PAYMENTS = 'type-payments'
export const RESOURCE_CERTIFICATIONS = 'certifications'
export const RESOURCE_NON_CONFORMITIES = 'non-conformity'
export const RESOURCE_TAXES = 'taxes'
export const RESOURCE_ROLES = 'roles'
export const RESOURCE_CAPABILITIES = 'capabilities'
export const RESOURCE_COMMENTS = 'comments'
export const RESOURCE_ACTIVITIES = 'activities'
export const RESOURCE_CATEGORY_GROUPS = 'category-groups'
export const RESOURCE_CONTRACT_DURATION = 'contract-duration'
export const RESOURCE_RATES = 'rates'

export const RESOURCE_OFFERS = 'offers'
export const RESOURCE_OFFER_LINES = 'offer-lines'

export const RESOURCE_SALE_INVOICES = 'sale-invoices'
export const RESOURCE_SALE_INVOICE_LINES = 'sale-invoice-lines'
export const RESOURCE_SALE_INVOICE_COLLECTS = 'sale-invoice-collects'
export const RESOURCE_SALE_INVOICE_TYPES = 'sale-invoice-types'
export const RESOURCE_SALE_INVOICE_REPORTS_PENDING_PAYMENT = 'sale-invoices-report-pending-collect'

export const RESOURCE_PURCHASE_INVOICES = 'purchase-invoices'
export const RESOURCE_PURCHASE_INVOICE_LINES = 'purchase-invoice-lines'
export const RESOURCE_PURCHASE_INVOICE_PAYMENTS = 'purchase-invoice-payments'
export const RESOURCE_PURCHASE_INVOICE_REPORTS = 'purchase-invoices-report-pending-payment'

export const RESOURCE_ORDERS = 'orders'
export const RESOURCE_ORDER_LINES = 'order-lines'

export const RESOURCE_RETENTIONS = 'retentions'

export const RESOURCE_DELIVERY_NOTES = 'delivery-notes'
export const RESOURCE_DELIVERY_NOTE_LINES = 'delivery-note-lines'

export const RESOURCE_JOBS = 'jobs'
export const RESOURCE_JOBS_ADDITIONAL_LINES = 'jobs-additional-lines'
export const RESOURCE_JOBS_EXPECTED_COST_LINES = 'jobs-expected-cost-lines'
export const RESOURCE_JOBS_CONTRACTS = 'job-contracts'
export const RESOURCE_JOBS_REPORTS = 'job-reports'
export const RESOURCE_JOBS_REPORTS_SERVICES = 'job-report-service'

export const RESOURCE_PRODUCTS = 'products'
export const RESOURCE_PRODUCT_CATEGORIES = 'productcategories'
export const RESOURCE_PRODUCT_ATTR_CONFIGURATION = 'productattrconfiguration'

export const RESOURCE_CERTIFICATION_REPORT = 'certification-report'

export const RESOURCE_MAINTENANCE_CERTIFICATIONS = 'maintenance-certifications'
export const RESOURCE_MAINTENANCE_CERTIFICATIONS_LINES = 'maintenance-certification-lines'

export const RESOURCE_PROVISIONS = 'provisions'
export const RESOURCE_PROVISION_CATEGORIES = 'provision-categories'

export const RESOURCE_FINANCIAL_LIMIT_REPORT = 'financial-limit-report'

export const RESOURCE_SALES_ORDERS_REPORT = 'order-sales-report'
