import auth from '@/middleware/auth'
import { RESOURCE_CONTACTS } from '@/shared/constants/resources'

export default [
  {
    path: '/contacts/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONTACTS,
      title: 'Contacto',
    },
    name: 'viewContact',
    component: () => import('@/views/contacts/View.vue'),
  },
  {
    path: '/contacts/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONTACTS,
      title: 'Editar contacto',
    },
    name: 'editContact',
    component: () => import('@/views/contacts/Edit.vue'),
  },
  {
    path: '/contacts/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONTACTS,
      title: 'Crear contacto',
    },
    name: 'createContact',
    component: () => import('@/views/contacts/Create.vue'),
  },
]
