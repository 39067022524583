import Vue from 'vue'
import DownloadService from '@/shared/services/download-service'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_PURCHASE_INVOICES } from '@/shared/constants/resources'

class PurchaseInvoicesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  verifyOrder(orderId) {
    return Vue.prototype.$http.post(`${this.resource}/verify-order/${orderId}`)
  }

  addPurchaseInvoiceToOrder(orderId) {
    return Vue.prototype.$http.post(`${this.resource}/invoice-order/${orderId}`)
  }

  async printPurchaseInvoice(purchaseInvoiceId) {
    const response = await Vue.prototype.$http.post(`${this.resource}/print-invoice/${purchaseInvoiceId}`, {}, { responseType: 'blob' })

    return new Promise(resolve => {
      const filename = DownloadService.getFilenameFromResponse(response)
      DownloadService.resolveAndDownloadBlob(response.data, filename)
      resolve()
    })
  }
}

export default new PurchaseInvoicesApi(RESOURCE_PURCHASE_INVOICES)
