import { BaseApi } from '@/api/base-api'
import { RESOURCE_PRODUCT_ATTR_CONFIGURATION } from '@/shared/constants/resources'
import axios from 'axios'
import Vue from 'vue'

class ProductAttrConfigurationApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  setCategoryAttributesOrder(categoryAttributeIds) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/${this.resource}/reorder`, { rows: categoryAttributeIds })
        resolve(response.data)
        Vue.$toast('Campo personalizados ordenados con éxito.')
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al ordenar los campos personalizados. Por favor, inténtelo de nuevo mas tarde.')
        console.error(error)
        reject(error)
      }
    })
  }
}

export default new ProductAttrConfigurationApi(RESOURCE_PRODUCT_ATTR_CONFIGURATION)
