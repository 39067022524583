import auth from '@/middleware/auth'
import { RESOURCE_CERTIFICATIONS } from '@/shared/constants/resources'

export default
[
  {
    path: '/certifications',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CERTIFICATIONS,
      headerVisible: false,
    },
    name: 'certifications',
    component: () => import('@/views/certifications/List.vue'),
  },
]
