import auth from '@/middleware/auth'
import { RESOURCE_PURCHASE_INVOICES } from '@/shared/constants/resources'

export default
[
  {
    path: '/purchase-invoices',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PURCHASE_INVOICES,
      headerVisible: false,
    },
    name: 'purchaseInvoices',
    component: () => import('@/views/purchase-invoices/List.vue'),
  },
  {
    path: '/purchase-invoices/view/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PURCHASE_INVOICES,
      navActiveLink: 'purchaseInvoices',
      title: 'Factura',
    },
    name: 'viewPurchaseInvoice',
    query:['origin'],
    component: () => import('@/views/purchase-invoices/View.vue'),
  },
  {
    path: '/purchase-invoices/create',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PURCHASE_INVOICES,
      navActiveLink: 'purchaseInvoices',
      title: 'Crear factura',
    },
    name: 'createPurchaseInvoice',
    query:['origin'],
    component: () => import('@/views/purchase-invoices/Create.vue'),
  },
  {
    path: '/purchase-invoices/edit/:id',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PURCHASE_INVOICES,
      navActiveLink: 'purchaseInvoices',
      title: 'Editar factura',
    },
    name: 'editPurchaseInvoice',
    query:['origin'],
    component: () => import('@/views/purchase-invoices/Edit.vue'),
  },
  {
    path: '/purchase-invoices-reports/view',
    meta: {
      middleware: [auth],
      resource: RESOURCE_PURCHASE_INVOICES,
      navActiveLink: 'purchaseInvoicesReports',
      title: 'Informe facturas pendientes de pago',
    },
    name: 'purchaseInvoicesReports',
    query:['origin'],
    component: () => import('@/views/purchase-invoices-reports/View.vue'),
  },
]
