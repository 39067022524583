/* eslint-disable no-shadow,no-param-reassign,consistent-return */
import ApiRestService from '@/api/base-api'
import { RESOURCE_DELIVERY_NOTES, RESOURCE_DELIVERY_NOTE_LINES } from '@/shared/constants/resources'

const state = {
  deliveryNotes: null,
  deliveryNotesLines: [],
  filters: {},
  search: null,
}

const mutations = {
  setDeliveryNotes(_state, deliveryNotes) {
    state.deliveryNotes = deliveryNotes
  },
  setDeliveryNotesLines(_state, deliveryNotesLines) {
    state.deliveryNotesLines = deliveryNotesLines
  },
  setDeliveryNotesTax(_state, deliveryNotesTax) {
    state.deliveryNotes.tax = deliveryNotesTax
  },
  setDeliveryNotesDocumentation(_state, documentation) {
    state.deliveryNotes.documentation = documentation
  },
  setFilters(_state, filters) {
    state.filters = filters
  },
  setSearch(_state, search) {
    state.search = search
  }
}

const actions = {
  loadDeliveryNotes({ commit }, deliveryNotesId) {
    if (!deliveryNotesId) {
      return
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await Promise.all([
          ApiRestService.get(deliveryNotesId, RESOURCE_DELIVERY_NOTES),
          ApiRestService.list(
            { filters: { delivery_note_id: deliveryNotesId } },
            RESOURCE_DELIVERY_NOTE_LINES,
          ),
        ])
        commit('setDeliveryNotes', response[0].data)
        commit('setDeliveryNotesLines', response[1].data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setDeliveryNotes({ commit }, deliveryNotes) {
    if (!deliveryNotes) {
      return
    }

    commit('setDeliveryNotes', deliveryNotes)
  },
  setDeliveryNotesTax({ commit }, deliveryNotesTax) {
    if (!state.deliveryNotes) {
      return
    }

    commit('setDeliveryNotesTax', deliveryNotesTax)
  },
  setDeliveryNotesDocumentation({ commit, state }, documentation) {
    if (!state.deliveryNotes) {
      return
    }

    commit('setDeliveryNotesDocumentation', documentation)
  },
}

const getters = {
  getDeliveryNotes: () => state.deliveryNotes,
  getDeliveryNotesLines: () => state.deliveryNotesLines,
  getFilters: () => state.filters,
  getSearch: () => state.search,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
