import auth from '@/middleware/auth'
import { RESOURCE_CONTACTS } from '@/shared/constants/resources'

export default [
  {
    path: '/configuration/',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONTACTS,
      title: 'Configuración',
    },
    name: 'configuration',
    component: () => import('@/views/configuration/Index.vue'),
  },
]
